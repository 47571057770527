import { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('pages/HomePage'));
const Product = lazy(() => import('pages/Product'));
const Traceability = lazy(() => import('pages/Traceability'));

const Router = () => {
  const { t } = useTranslation('routes');

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Cargando...</div>}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/pampeana" />} />
          <Route exact path="/pampeana" component={Home} />
          <Route
            exact
            path={`/pampeana/${t('product')}/:id`}
            component={Product}
          />
          <Route
            path={`/pampeana/${t('product')}/:id/${t('traceability')}`}
            component={Traceability}
          />
          <Route exact path="*" render={() => <Redirect to="/pampeana" />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;

import styled from 'styled-components';

export const AppContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.background.main};
  color: ${(props) => props.theme.colors.primary.main};
  text-align: center;
`;

import { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import ThemeContext from 'contexts/ThemeContext';
import themes from 'themes';
import hasWindow from 'utils/hasWindow';
import CssBaseline from '@material-ui/core/CssBaseline';

const getLocalStorageTheme = () => window.localStorage.getItem('theme');
const setLocalStorageTheme = (mode) =>
  window.localStorage.setItem('theme', mode);

/**
 * checking theme for SSR
 */
const getInitialTheme = () => {
  if (!hasWindow()) return 'default';
  if (!hasWindow('localStorage')) return 'default';
  return getLocalStorageTheme() || 'default';
};

const Theme = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme);

  const setMode = (mode) => {
    setLocalStorageTheme(mode);
    setTheme(mode);
  };

  const toggleDark = () =>
    theme === 'dark' ? setMode('default') : setMode('dark');

  /**
   * set localStorage theme for SSR using useEffect
   * to avoid rehydration problem.
   */
  useEffect(() => {
    const localTheme = getLocalStorageTheme();
    setMode(localTheme);
  }, []);

  const current = themes[theme] || themes.default;

  return (
    <ThemeContext.Provider value={{ theme, setTheme, toggleDark }}>
      <CssBaseline />
      <ThemeProvider theme={current}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default Theme;

import '../base/fonts/index.css';

const units = [
  '0',
  '2px',
  '4px',
  '6px',
  '8px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '26px',
];

const theme = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [{ fontFamily: 'Gotham' }],
      },
    },
  },
  colors: {
    background: {
      main: '#FFFFFF',
      secondary: '#163353',
      shadowed: '#E8E8EA',
    },
    primary: {
      main: '#114172',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#E5AF6E',
      contrastText: '#FFFFFF',
    },
    hover: {
      main: '#D6E0F0',
    },
    warning: {
      main: '#FFA500',
    },
    danger: {
      main: '#FF0000',
    },
  },
  fonts: {
    primary: 'Gotham',
    secondary: 'Gotham Bold',
    colors: {
      primary: '#114172',
      secondary: '#E5AF6E',
      disabled: '#D3D3D3',
    },
    size: {
      verySmall: units[5],
      small: units[6],
      medium: units[7],
      big: units[9],
    },
  },
  spacing: units,
  header: {
    padding: {
      desktop: `${units[12]} ${units[12]} ${units[7]} ${units[12]}`,
    },
    shadow: `${units[0]} ${units[0]} ${units[5]} ${units[0]} rgb(0 0 0 / 50%)`,
  },
  footer: {
    padding: {
      desktop: `54px ${units[0]} 40px ${units[0]}`,
    },
  },
  hero: {
    margin: {
      desktop: `60px 100px`,
      mobile: `60px 30px`,
    },
  },
  zIndex: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  breakpoints: {
    mobile: 480,
    smallTablet: 700,
    tablet: 1024,
    laptop: 1400,
    desktop: 1920,
  },
};
export default theme;

const en = {
  titles: {
    home: 'Pampeana Traceability',
    product: 'Pampeana Product',
  },
  home: {
    title: 'Product',
    form: {
      placeholder: 'Batch number',
      search: 'SEARCH',
      batchNumber: 'Batch number',
      loading: 'Searching...',
      notFound: 'Batch not found.',
      error: 'Network error. Please try again.',
    },
    info: {
      firstStep:
        'Seek for the product label placed at the back of the packaging',
      secondStep: 'Insert the batch number and request all the information',
    },
  },
  header: {
    title: 'The Pampeana',
    backToSheet: 'BACK TO SHEET',
  },
  footer: {
    allRightsReserved: '© Carnes Pampeana 2020 | All rights reserved.',
    legalWarning: 'Legal Warning',
    privacyPolicy: 'Privacy Policy',
    cookiesPolicy: 'Cookies Policy',
  },
  hero: {
    title: 'Front-end architecture: Project Configuration (React)',
    subtitle:
      'Guideline to structure and setup a front-end project in react for Scalable Application.',
  },
  routes: {
    product: 'product',
    traceability: 'traceability',
  },
  product: {
    yourProductPampeana: 'Your Pampeana project:',
    requestCompleteTraceability: 'Request complete traceability',
    verifiedWithBlockchain: 'Verified with blockchain',
    sourceMap: 'Source Map',
    meta: {
      Cría: 'Breeding',
      Corte: 'Cut',
      Raza: 'Breed',
      faena_date: 'Departure date',
      grade: 'Quality',
      flight_date: 'Flight Date',
      flight_number: 'Flight Number',
      delivery_date: 'Delivery Date',
      cria: 'Breeding',
      cut: 'Cut',
      raza: 'Breed',
    },
    maturation: {
      title: 'Maturation',
      states: {
        notDefined: 'Not available',
        bad: 'Bad',
        good: 'Good',
        veryGood: 'Very good',
        excellent: 'Excellent',
      },
      message: '{{count}} day',
      message_plural: '{{count}} days',
    },
    pending: 'Pending...',
  },
  traceability: {
    verifyData: 'Check verification data',
    noPostsFound: 'No Posts found..',
    open: 'Open +',
    close: 'Close -',
  },
  post: {
    memo: {
      recipe: 'Recipe (Link)',
      maturation: 'Maturation',
      addressee_customer: 'Addressee customer',
      delivery_date: 'Delivery date',
      arrival_temp: 'Arrival temperature',
      packing_control: 'Packing control',
      flight_number: 'Flight number',
      flight_date: 'Flight date',
      load_temp: 'Load temperature',
      departure_date: 'Departure date',
      airport: 'Airport',
      grade: 'Grade',
      number_of_animals: 'Number of animals',
      faena_date: 'Job date',
      tropa: 'Troop',
      tipo: 'Type of marbling',
      raza: 'Race',
      cria: 'Breeding',
    },
  },
  tooltip: {
    Raza: 'Pampeana selects the best farm animals of the following breedings: Hereford, Angus, Bradford, Limusin, Holstein, Brahman.',
  },
};

export default en;

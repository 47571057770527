import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './locales/es';
import en from './locales/en';

i18n.use(initReactI18next).init({
  initImmediate: false,
  preload: ['en', 'es'],
  lng: 'es',
  fallbackLng: 'en',
  interpolation: {},
  resources: {
    en,
    es,
  },
});

export default i18n;

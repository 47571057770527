import { createContext, useContext } from 'react';

export const ThemeContext = createContext({ theme: 'dark' });

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error(
      'ThemeContext must be used within a ThemeContenxt.Provider'
    );
  }
  return context;
};

export default ThemeContext;

import { AppContainer } from './styles';
import Router from 'router';
import ThemeProvider from 'providers/ThemeProvider';
import I18nProvider from 'providers/I18nProvider';
import ApolloProvider from 'providers/ApolloProvider';

const App = () => {
  return (
    <ApolloProvider>
      <I18nProvider>
        <ThemeProvider>
          <AppContainer data-component="app-container">
            <Router />
          </AppContainer>
        </ThemeProvider>
      </I18nProvider>
    </ApolloProvider>
  );
};

export default App;

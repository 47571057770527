const theme = {
  colors: {
    background: {
      main: '#1a202c',
    },
    container: {
      main: '#2d3748',
    },
    hover: {
      main: '#1e5f74',
    },
  },
  fonts: {
    primary: 'Gotham',
    secondary: 'Gotham Bold',
    colors: {
      primary: '#edf2f7',
      secondary: '#a0aec0',
    },
    size: '16px',
  },
  spacing: [0, '2px', '4px', '8px', '10px', '12px', '14px', '16px', '18px'],
  header: {
    height: '4.75rem',
    shadow: 'none',
  },
  footer: {
    shadow: 'none',
  },
  zIndex: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  breakpoints: {
    mobile: 480,
    smallTablet: 700,
    tablet: 1024,
    laptop: 1400,
    desktop: 1920,
  },
};

export default theme;

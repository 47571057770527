const es = {
  titles: {
    home: 'Trazabilidad Pampeana',
    product: 'Producto Pampeana',
  },
  home: {
    title: 'Producto',
    form: {
      placeholder: 'N* de lote',
      search: 'BUSCAR',
      batchNumber: 'Número de lote',
      loading: 'Buscando...',
      notFound:
        'El lote que ingreso no se encuentra. Por favor verfique si es correcto e intente nuevamente.',
      error:
        'Ha ocurrido un error al buscar el lote. Por favor intente nuevamente mas tarde.',
    },
    info: {
      firstStep:
        'Consulta la etiqueta del producto que encontrarás detrás del envase',
      secondStep: 'Introduce el número de lote y consulta toda la informacion',
    },
  },
  header: {
    title: 'La Pampeana',
    backToSheet: 'VOLVER A LA FICHA',
  },
  footer: {
    allRightsReserved:
      '© Carnes Pampeana 2020 | Todos los derechos reservados.',
    legalWarning: 'Aviso Legal',
    privacyPolicy: 'Política de Privacidad',
    cookiesPolicy: 'Política de Cookies',
  },
  hero: {
    title: 'Arquitectura Front-end: Proyecto (React)',
    subtitle:
      'Un subtitulo un poco vende humo y largo para rellenar espacio y decir que es un repo de ejemplo de React 🤫😉',
  },
  routes: {
    product: 'producto',
    traceability: 'trazabilidad',
  },
  product: {
    yourProductPampeana: 'Tu producto Pampeana:',
    requestCompleteTraceability: 'Consultar trazabilidad completa',
    verifiedWithBlockchain: 'Verificado en blockchain',
    sourceMap: 'Mapa de orígen',
    meta: {
      Cría: 'Cría',
      Corte: 'Corte',
      Raza: 'Raza',
      faena_date: 'Fecha de salida',
      grade: 'Calidad',
      flight_date: 'Fecha de Vuelo',
      flight_number: 'Número de Vuelo',
      delivery_date: 'Fecha de Entrega',
      cria: 'Cría',
      cut: 'Corte',
      raza: 'Raza',
    },
    maturation: {
      title: 'Maduración',
      states: {
        notDefined: 'No disponible',
        bad: 'Mala',
        good: 'Buena',
        veryGood: 'Muy buena',
        excellent: 'Excelente',
      },
      message: '{{count}} día',
      message_plural: '{{count}} días',
    },
    pending: 'Pendiente...',
  },
  traceability: {
    verifyData: 'Comprueba verificacion datos',
    noPostsFound: 'No se encontraron registos..',
    open: 'Abrir +',
    close: 'Cerrar -',
  },
  post: {
    memo: {
      recipe: 'Receta (Link)',
      maturation: 'Maduración',
      addressee_customer: 'Destino Cliente',
      delivery_date: 'Fecha de Entrega',
      arrival_temp: 'Temp. de Llegada',
      packing_control: 'Control de Envasado',
      flight_number: 'Número de vuelo',
      flight_date: 'Fecha de vuelo',
      load_temp: 'Temp. de embarque',
      departure_date: 'Fecha de salida',
      airport: 'Aeropuerto',
      grade: 'Calificación',
      number_of_animals: 'Cant. de animales',
      faena_date: 'Fecha de salida',
      tropa: 'Tropa',
      tipo: 'Tipo de marmoleado',
      raza: 'Raza',
      cria: 'Cría',
    },
  },
  tooltip: {
    Raza: 'En Pampeana seleccionamos las mejores reses entre las siguientes razas:\nHereford, Angus, Bradford, Limusin, Holstein, Brahman.',
  },
};

export default es;
